@import 'scss/variables';
@import 'scss/fonts';
@import 'scss/about';
@import 'scss/earn';
@import 'scss/footer';
@import 'scss/header';
@import 'scss/modal';
@import 'scss/select';
@import 'scss/swapper';
@import 'scss/transform';

* {
    box-sizing: border-box;
}

html, body {
    margin: 0;
    padding: 0;
}

body {
    color: $dark-grey;
    font-family: 'Circular Std';
    font-size: 14px;
}

.container {
    width: 100%;
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto;
}

a {
    color: $dark-grey;
    text-decoration: none;
}

p {
    line-height: 1.5;
    margin: 0;
}

h2 {
    font-size: 30px;
    margin: 0;

    @media (max-width: $max-md) {
        font-size: 24px;
    }
}

input {
    font-family: 'Circular Std';
}

button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: $green;
    font-weight: 600;
    padding: 0 30px;
    height: 40px;
    border-radius: 20px;
    text-align: center;
    border: none;
    outline: none;
    cursor: pointer;
}