.about {
    max-width: 620px;
    margin: 0 auto;
    text-align: center;

    h2 {
        margin-bottom: 20px;
    }

    & > div + div {
        margin-top: 150px;
    }
    
    .token svg {
        height: 40px;
        width: auto;
        margin-right: 20px;
        vertical-align: middle;
    }

    @media(max-width: $max-md) {
        margin-top: 70px;
    }
}