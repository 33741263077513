.earn {
    margin-top: 150px;

    .background {
        background-image: url("../images/bg2.svg");
        background-position: 50% 210px;
        background-repeat: no-repeat;
        min-height: 100vh;
        width: 100%;
        position: absolute;
        top: 0;
        z-index: -1;
    }

    .balance {
        margin-top: 40px;
        text-align: center;
    }

    .balance span {
        font-size: 16px;
        font-weight: 600;
    }

    .balance p {
        font-size: 24px;
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .balance svg {
        height: 30px;
        width: auto;
        margin-right: 10px;
    }

    .swapper {
        margin-top: 60px;
    }
}
