.footer {
    margin-top: 150px;
    padding: 30px 0;
    
    .container {
        display: flex;
        justify-content: space-between;
    }

    .links a {
        color: $dark-grey;
        font-weight: 500;
        transition: color 0.2s ease-in-out;

        & + a {
            margin-left: 20px;
        }

        &:hover {
            color: $green;
        }
    }

    @media(max-width: $max-md) {
        padding: 15px 0;
    }
}