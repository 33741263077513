.transform {
    margin-top: 150px;

    .background {
        background-image: url("../images/bg1.svg");
        background-position: 50% 140px;
        background-repeat: no-repeat;
        min-height: 100vh;
        width: 100%;
        position: absolute;
        top: 0;
        z-index: -1;
    }

    .about {
        margin-top: 150px;
    }
}
