.modal {
    &:before {
        content: '';
        background-color: rgba(0, 0, 0, 0.75);
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1;
    }
    
    .content {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
    }

    .blob {
        font-size: 18px;
        background-color: white;
    }
}