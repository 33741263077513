.swapper {
    width: 460px;
    margin: 0 auto;
    
    .bg {
        display: flex;
        justify-content: space-between;
        background-color: $light-grey;
        width: 100%;
        padding: 20px 20px 20px 40px;
        border-radius: 47.5px;

        & + .bg {
            margin-top: 20px;
        }

        .input-wrapper {
            display: flex;
            flex-direction: column;
        }

        .input {
            background-color: $light-grey;
            font-size: 24px;
            outline: none;
            border: none;
            margin-top: 8px;
        }

        .select {
            display: flex;
            align-items: center;
        }
    }

    .bg:not(.full) {
        .input {
            width: 200px;
        }
    }

    .arrow {
        display: flex;
        justify-content: center;
        margin: 20px 0;
    }

    .arrow img {
        cursor: pointer;
    }

    .fee {
        text-align: center;
        margin-top: 15px;
    }

    .address {
        height: 40px;
        width: calc(100% - 80px);
        margin: 40px 40px 0;
        border: none;
        border-bottom: 2px solid #e9e9e9;
        outline: none;
    }

    .bonus {
        font-weight: 600;
        text-align: center;
        margin-top: 20px;

        svg {
            height: 20px;
            width: auto;
            margin-right: 8px;
            vertical-align: middle;
        }
    }

    button {
        color: white;
        height: 50px;
        width: 250px;
        margin: 40px auto 0;
        border-radius: 25px;

        &:disabled {
            cursor: not-allowed;
        }
    }

    .error {
        color: #eb4034;
        text-align: center;
        margin-top: 20px;
    }

    @media(max-width: $max-sm) {
        width: 100%;
    }
}
