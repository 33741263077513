.header {
    .warning {
        color: white;
        background-color: $green;
        text-align: center;
        padding: 10px 0px;
    }

    .container {
        display: flex;
        padding: 30px 0;
        justify-content: space-between;
        align-items: center;

        .logo {
            height: 40px;
            width: auto;
        }

        .links {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }

        .links a {
            color: $dark-grey;
            font-weight: 500;
            transition: color 0.2s ease-in-out;

            & + a {
                margin-left: 20px;
            }

            &:hover, &.active {
                color: $green;
            }
        }

        .address {
            position: relative;
        }

        .address:before {
            content: '';
            background-color: $green;
            height: 5px;
            width: 5px;
            position: absolute;
            top: 50%;
            left: -15px;
            transform: translateY(-50%);
        }
    }
}
